<template>

<v-layout justify-center>
  <v-dialog v-model="dialog"  fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn block color="secondary" v-on="on" class="mt-2" dark>Crear una cuenta</v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title row justify-center>
        <h3 class="headline text-md-center" v-show="!sended">Introduce un alias cualquiera y una contraseña para registrarte</h3>
        <h3 class="headline text-md-center" v-show="sended">Proceso de registro correcto</h3>
      </v-card-title>
      <v-form ref="form">
        <v-card-text v-show="!sended">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Alias" :rules="[validateRequired, validateName,validateAlphanumeric]" v-model="alias"></v-text-field>
                <v-text-field label="Contraseña" :rules="[validateRequired, validatePassword]" v-model="password"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text v-show="sended">
          Revisa la bandeja de entrada de {{email}} para que verifiquemos que el correo es correcto, y puedas elegir una contraseña
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-show="!sended" color="warning" @click="dialog = false" v-if="$store.state.vuetify === 1.5">Cancelar</v-btn>
        <v-btn v-show="!sended" color="warning" text @click="dialog = false" v-if="$store.state.vuetify === 2">Cancelar</v-btn>
        <v-btn v-show="!sended" :loading="loading" color="success" @click="registerRequest">OK</v-btn>
        <v-btn v-show="sended" color="success" @click="dialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-layout>

</template>

<script>

import legalPoems from '../appComponents/LegalPoems.vue'

export default {
  name: 'registerRequest',
  data() {
    return {
      sended: false,
      dialog: false,
      alias: '',
      password: '',
      loading: false,
      accept: false
    }
  },
  components: {
    legalPoems
  },
  beforeMount(){
    if (this.$route.params && this.$route.params.type && this.$route.params.type === 'register'){
      this.dialog = true
    }
  },
  methods: {
    registerRequest: function(){
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      this.$store.commit('recalculateCaptcha')
      let resource = 'user';
      this.$http.post(this.$store.state.urlApi+resource, { 
            name: this.alias,
            password: this.password
        }, { headers: {
            Accept: 'application/json',
            'Captcha-Authorization': this.$store.state.captchaTokenResult,
            Authorization: this.$store.state.accessToken
      }}).then(function() {
          this.$store.state.anonymous = false
          this.$store.state.userName = this.alias
          this.$root.$emit('changeSnackbar', { show : true , text: 'Cuenta creada con éxito, no olvides tu alias y contraseña', timeout: 8000, type: 'success' } )
          this.dialog = false;
      }, response => {
        this.dialog = false;
        this.loading = false;
        let message = response.body.error === 'user_already_exists' ? 'El alias ya existe, elige otro' : 'Ocurrió un error inesperado, inténtelo más tarde';
        let type = response.body.error === 'user_already_exists' ? 'warning' : 'error';
        this.$root.$emit('changeSnackbar', { show : true , text: message, timeout: 4000, type } )
      });
    },
    validateName: function(value){
      return (typeof value !== 'undefined' && value.length >= 6 && value.length <= 10) || 'Longitud entre 6 y 10'
    },
    validateAlphanumeric: function(value) {
      return /^[a-zA-Z0-9]+$/.test(value) || 'No utilices espacios ni caracteres extraños';
    },
    validateRequired: function(value){
      return !!value || 'obligatorio'
    },
    validateAccept: function(value){
      return value || 'Debes aceptar la política de privacidad de poemsandlearn';
    },
    validatePassword: function(value){
      return (typeof value !== 'undefined' && value.length >= 6 && value.length <= 10) || 'Longitud entre 6 y 10'
    }
  }
}
</script>

<style>

</style>
