<template>
<div>
<bar logout="true" progress="true"/>

<v-content>
  <message/>
<v-container grid-list-md>
  <v-layout row wrap>

      <v-flex md12 sm12 xs12>
        <v-layout row wrap>

          <v-flex xs12 v-if="!$store.state.anonymous">
            <v-sheet
              color="dark"
              class="pa-2"
            >
              <v-subheader>alias:</v-subheader>
              <v-text-field v-model="$store.state.userName" outline readonly single-line/>
            </v-sheet>
          </v-flex>
          
          <v-flex xs12 >
            <v-sheet
              color="dark"
              class="pa-2"
            >
              <v-subheader>Configura el número de palabras que tendrás que acertar cada día para conseguir el punto de racha</v-subheader>
              <v-slider
                class="mt-6"
                v-model="increaseWordsObjetive"
                thumb-label="always"
                step="30"
                :max="100"
                :min="10"
                :tick-labels="levels"
                :readonly="this.loading"
                @change="save()"
              ></v-slider>
            </v-sheet>
          </v-flex>

          <v-flex xs12 v-if="false">
            <v-sheet
              color="dark"
              class="pa-2 text-center"
            >
              <v-subheader>Modificar contraseña</v-subheader>
              <passwordRequest :forgot="false"/>

            </v-sheet>
          </v-flex>

          <v-flex xs12 v-if="!$store.state.anonymous">
            <v-sheet
              color="red"
              class="pa-2 text-center"
            >
              <v-subheader>Eliminación de la cuenta</v-subheader>
              <deleteRequest/>

            </v-sheet>
          </v-flex>

          <v-flex xs12 v-if="$store.state.anonymous" class="text-xs-center text-center mt-2">
            <v-divider class="mb-2"></v-divider>
            <p class="text-xs-center text-center"><strong>Regístrate</strong> para mantener tu progreso y poder acceder desde otros dispositivos</p>
            <register-request/>
            <v-divider></v-divider>
          </v-flex>

        
        </v-layout>


      </v-flex>
  </v-layout>
</v-container>
<simple-modal/>
</v-content>

<pl-footer/>

</div>

</template>

<script>

import deleteRequest from '../components/requestUtils/DeleteRequest.vue'
import passwordRequest from '../components/requestUtils/PasswordRequest.vue'
import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'
import registerRequest from '../components/requestUtils/RegisterRequest.vue'
import simpleModal from '../components/modals/SimpleModal.vue'
import checkToken from '../plugins/checkToken'

export default {
  name: 'Settings',
  data() {
    return {
      increaseWordsObjetive: this.$store.state.increaseWordsObjetive,
      loading: false,
      levels: [
        'mosca',
        'gallo',
        'welter',
        'pesado'
      ]
    }
  },
  components: {
    deleteRequest,
    passwordRequest,
    'pl-footer':footer,
    message,
    bar,
    'register-request' : registerRequest,
    'simple-modal' : simpleModal
  },
  beforeMount: function(){
    this.$store.state.toStartRequest = '/home'
  },
  methods: {
    home: function(){
      this.$store.state.currentRoute = '/home';
      this.$router.push('home');
    },
    save: function(){
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.loading = true;
      this.$http.patch(this.$store.state.urlApi+'settings', {
          increaseWordsObjetive: this.increaseWordsObjetive,
          language: 'spa'
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        this.$store.state.increaseWordsObjetive = response.body.increaseWordsObjetive;
        window[this.$store.state.storage].setItem('state'+window.location.pathname, JSON.stringify(this.$store.state))
        this.$store.commit('refreshMessageToday')
        this.loading = false;
      }, function() {
        this.$store.state.refreshHome = true
        this.$root.$emit('changeSnackbar', { show : true , text : 'Lo sentimos, no se pudo realizar la acción, inténtelo más tarde', timeout: 4000, type: 'warning' } )
        this.loading = false;
        this.increaseWordsObjetive = this.$store.state.increaseWordsObjetive;
      });
    }
  }
}
</script>

<style>

</style>
