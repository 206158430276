<template>

<v-dialog v-model="dialog" persistent max-width="400px">
  <template v-slot:activator="{ on }">
    <v-btn color="error" v-on="on" large>Eliminar</v-btn>
  </template>
  <v-card>
    <v-card-title row justify-center>
      <h3 class="headline text-md-center" v-show="!sended">Eliminación de la cuenta</h3>
      <h3 class="headline text-md-center" v-show="sended">Revisa tu correo</h3>
    </v-card-title>
    <v-form ref="form">
      <v-card-text v-show="!sended">
        Si deseas eliminar tu cuenta pulsa OK y tu cuenta será eliminada por completo.
      </v-card-text>
      <v-card-text v-show="sended">
        Revisa la bandeja de entrada de tu correo, podrás eliminar tu cuenta desde el enlace que te hemos enviado
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-show="!sended" color="warning" text @click="dialog = false">Cancelar</v-btn>
      <v-btn v-show="!sended" color="error" :loading="loading" @click="deleteRequest">OK</v-btn>
      <v-btn v-show="sended" color="success" @click="dialog = false">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

</template>

<script>

export default {
  name: 'DeleteRequest',
  data() {
    return {
      loading: false,
      dialog: false,
      sended: false
    }
  },
  methods: {
    deleteRequest: function(){
      this.loading = true;
      this.$store.commit('recalculateCaptcha')
      this.$http.delete(this.$store.state.urlApi+'user', { headers: {
            Accept: 'application/json',
            Authorization: this.$store.state.accessToken,
            'Captcha-Authorization': this.$store.state.captchaTokenResult
      }}).then(function() {
        this.$root.$emit('changeSnackbar', { show : true , text: 'Has eliminado definitivamente tu cuenta, gracias por haber confiado en nosotros', timeout: 8000, type: 'success' } )
        this.$store.state.accessToken = ''
        this.$store.state.refreshToken = ''
        this.$store.state.currentRoute = '/'
        this.$router.push(this.$store.state.currentRoute);
      }, function() {
        this.dialog = false;
        this.$root.$emit('changeSnackbar', { 
          show : true , 
          text : 'Ocurrió un error inesperado, inténtelo más tarde' , 
          timeout: 4000, 
          type : 'error' 
        } )
      });
    }
  }
}
</script>

<style>

</style>
